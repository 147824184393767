var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.accion)+" Solicitud: "),(_vm.id)?_c('b',[_vm._v(_vm._s(_vm.id))]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Transporte Fluvial")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Solicitudes")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.accion == 'Editar' ? 'col-md-12' : 'col-md-12'},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Sitio de Cargue")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                                _vm.$store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ],attrs:{"label":"nombre","options":_vm.listasForms.sitios,"placeholder":"Sitio","disabled":_vm.form.estado == 2},on:{"input":function($event){return _vm.getSelectSitio()}},model:{value:(_vm.sitio),callback:function ($$v) {_vm.sitio=$$v},expression:"sitio"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Solicitud")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha),expression:"form.fecha"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',attrs:{"type":"date","disabled":_vm.form.estado == 2 || _vm.form.sitio_id == null},domProps:{"value":(_vm.form.fecha)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estado),expression:"form.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',attrs:{"disabled":_vm.form.estado == 2 ||
                                  _vm.form.fecha == null ||
                                  _vm.form.sitio_id == null},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.save()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.id,attrs:{"disabled":estado.numeracion === 2 && _vm.accion == 'Crear'},domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Observación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observaciones),expression:"form.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observaciones", $event.target.value)}}})])])]),_c('div',{staticClass:"card-foter"},[_c('button',{staticClass:"btn bg-secondary col-md-2",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.estado == 1),expression:"form.estado == 1"}],staticClass:"btn bg-gradient-info shadow col-md-2",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-save"}),_c('br'),_vm._v("Guardar ")])])]),(_vm.form.estado === 1)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-6"},[(
                                  _vm.$store.getters.can(
                                    'hidrocarburos.regaliasNominaciones.export'
                                  )
                                )?_c('button',{staticClass:"btn bg-gradient-info btn-sm float-right shadow",attrs:{"type":"button"},on:{"click":function($event){return _vm.plantilla()}}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_vm._v(" Plantilla ")]):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('input',{staticClass:"form-control",attrs:{"type":"file","id":"fileDocu","accept":".xls,.xlsx"},on:{"change":_vm.fileUpload}})])]),_vm._m(1)])])]):_vm._e()])]),_c('SolicitudFormDet',{ref:"SolicitudFormDet"})],1)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"card-title mt-2 font-weight-bold"},[_vm._v(" Archivos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"card card-outline card-navy"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('h6',[_c('i',{staticClass:"fas fa-file-signature"}),_vm._v(" Nota: ")])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('ol',[_c('li',[_vm._v(" El formato debe estar completo sin ningún campo en blanco. ")]),_c('li',[_vm._v(" El campo de Fecha cargue debe llevar un formato (“yyyy–mm-dd”). ")]),_c('li',[_vm._v(" El campo de Hora cargue debe llevar un formato (“HH:MM”). ")]),_c('li',[_vm._v(" El campo de Fecha solicitud debe llevar un formato (“yyyy–mm-dd”). ")])])])])])])
}]

export { render, staticRenderFns }